.dashTileHeight{
    max-height: 224px;
}
.incompleteProfile{
    border: solid 1px #f64e63!important;
    background-color: #fff;
}
.bgIcon{
    background-color: white;
    border-radius: 50%;
    display: inline-block;
    width: 54px;
    height: 54px;
    text-align: center;
    line-height: 50px;
}
.bgIcon svg{
    transition: all .5s linear;
}
.bgIcon:hover svg{
    transform: scale(1.10);
    transition: all .5s linear;

}
.cartIcon{
    color:#f6ad4e;
    font-size: 30px;
}
.handIcon{
    color:#814cf7;
    font-size: 30px;
}
.fileIcon{
    color:#3dc9bc;
    font-size: 30px;
}
.diamondIcon{
    color:#0072ff;
    font-size: 30px;
}
.cartTile {
    background-image: linear-gradient( 326deg, #ffc480, #ff763b);
}
.OrderPlaceTiles {
    background-image: linear-gradient( 325deg, #759bff, #843cf6);
}
.invoiceTile{
    background-image: linear-gradient( 149deg, #26A69A, #44d4c7);
}
.diamondRequestTile{
    background-image: linear-gradient( 325deg, #0e4cfd, #6a8eff);
}
